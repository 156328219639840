<template>
    <tr>
        <td><div class="input-group">
                    <button type="button" class="btn btn-default btn-flat">
                        <i class="fas fa-list"></i>
                    </button>
                    <input type="text" class="form-control input-sm" placeholder="Código">
                </div><input type="text"  class="form-control input-xs" placeholder="Concepto"></td>
        <td><textarea class="form-control input-xs" rows="3"></textarea></td>
        <td><input type="number"  class="form-control input-xs"></td>
        <td><input type="number"  class="form-control input-xs"></td>
        <td><input type="number"  class="form-control input-xs"></td>
        <td><input type="number"  class="form-control input-xs"></td>
        <td><input type="number"  class="form-control input-xs"></td>
        <td><select class="form-control input-xs"><option>Comp.</option></select>
            <select class="form-control input-xs"><option>21,0%</option></select></td>
        <td><input type="checkbox" ></td>
        <td><input type="checkbox" ></td>
        <td><input type="checkbox" ></td>
    </tr>
</template>